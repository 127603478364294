"use client";

import Image from "next/image";
import React, { useEffect } from "react";
import welcome1 from "../public/welcome.webp";
import welcome2 from "../public/welcome2.webp";
import welcome3 from "../public/welcome3.webp";
import { Check } from "lucide-react";
import { useTranslations } from "next-intl";
import { motion } from "framer-motion";
import { usePathname } from "next/navigation";

function Welcome() {
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";
  const t = useTranslations("Index");
  const [currentWidth, setCurrentWidth] = React.useState<number>(0);

  const backgroundVariants = {
    initial: {
      rotate: 0,
      x: 0,
      y: 40,
    },
    animate: {
      rotate: [0, 360, 0, 360],
      x: [
        -30,
        -30,
        lang === "ar" ? 600 : -600,
        lang === "ar" ? 600 : -600,
        -30,
      ],
      y: [40, 350, 350, 40, 40],
      transition: {
        duration: 10,
        ease: "linear",
        repeat: Infinity,
      },
    },
  };

  useEffect(() => {
    setCurrentWidth(window.innerWidth);
  }, []);

  return (
    <section id="welcome" className="py-24 sm:px-16 md:px-16 px-4 font-montserrat relative flex justify-between items-center flex-wrap gap-y-8">
      {currentWidth > 1024 && (
        <motion.div
          className={`absolute lg:block hidden lg:w-[15%] lg:h-[15%] w-[15%] h-[15%] inset-0 z-0 ${
            lang === "ar"
              ? "lg:right-[35%] md:right-[66%] sm:right-[52%] right-[40%]"
              : "lg:left-[35%] md:left-[66%] sm:left-[52%] left-[40%]"
          } lg:top-5 md:-top-2 sm:-top-28 -top-28`}
          style={{
            transformOrigin: "center",
          }}
          initial="initial"
          animate="animate"
          variants={backgroundVariants}
        >
          <div
            className="bg-welcome bg-no-repeat"
            style={{
              width: "100%",
              height: "100%",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          ></div>
        </motion.div>
      )}

      <div className="flex justify-between flex-wrap gap-3 w-full md:w-[45%] z-10">
        <div className="w-full md:w-[800px] ">
          <Image
            src={welcome1}
            alt="welcome"
            className="w-full"
            style={{
              maxWidth: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="w-full flex lg:flex-nowrap flex-no-wrap -translate-y-20 md:-translate-y-28">
          <div className="w-[50%] md:w-[200px] lg:w-[50%] lg:h-[100%] h-full md:h-[200px]">
            <Image
              src={welcome3}
              alt="welcome"
              className="w-full "
              style={{
                maxWidth: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="w-[50%] md:w-[100px] lg:w-[50%] lg:h-[100%] h-full md:h-[200px]">
            <Image
              src={welcome2}
              alt="welcome"
              className=" w-full"
              style={{
                maxWidth: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>

      <div className="md:w-[50%] w-full">
        <p
          className={`text-blue-800 text-2xl text-center font-semibold mb-6 ${
            lang === "ar" ? "md:text-right" : "md:text-left"
          } `}
        >
          {t("welcome_to_minops")}
        </p>
        <h2 className="text-slate-950 md:text-5xl text-xl md:text-start text-center font-bold mb-8">
          {t("we_provide")}
        </h2>
        <p className="text-slate-950 opacity-70 text-center md:text-start md:text-lg font-normal mb-9">
          {t("new_content")}
        </p>
        <div className="flex flex-wrap sm:grid justify-center sm:grid-cols-2 md:flex md:flex-wrap md:justify-between gap-y-4 relative">
          {[
            "first_service",
            "second_service",
            "third_service",
            "fourth_service",
            "fivth_service",
            "sixth_service",
          ].map((serviceKey, index) => (
            <div
              key={index}
              className="flex gap-x-2 items-center md:w-1/2 w-full text-center"
            >
              <Check
                width={15}
                height={15}
                className="text-white bg-gradient-to-b from-blue-800 to-blue-600 rounded-full"
              />
              <h3 className="text-slate-950 text-sm font-semibold">
                {t(serviceKey)}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Welcome;
