"use client";
import { useTranslations } from "next-intl";
import Footer from "@/components/footer";
import Welcome from "@/components/welcome";
import Logos from "@/components/logos";
import Services from "@/components/services";
import Box from "@/components/box";
import OurProducts from "@/components/our-products";
import WhoWeAre from "@/components/whoWeAre";
import Problems from "@/components/problems";
import Customize from "@/components/customize";
import Solutions from "@/components/soultions";
import New from "@/components/new";
import Hero from "@/components/hero";
import Question from "@/components/question";
import NewHero from "@/components/newHero";

export default function Index() {
  const t = useTranslations("Index");

  return (
    <div className="relative overflow-hidden">
      {/* <Hero /> */}
      <NewHero />
      <Welcome />
      {/* <Logos /> */}
      <Services />
      <Box />
      <WhoWeAre />
      <Problems />
      <Customize />
      <OurProducts />
      <Solutions />
      <New />
      <Question />
      <Footer />
      {/* <Curosr /> */}
    </div>
  );
}
