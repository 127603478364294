'use client'
import React, { useEffect, useState } from "react";
import cloudServices from "../public/cloud_services_serv.webp";
import security from "../public/security_serv.webp";
import uiux from "../public/uiux_serv.webp";
import webDev from "../public/web_dev_serv.webp";
import mobileDev from "../public/mobile_serv.webp";
import customSW from "../public/custom_sw_serv.webp";
import devops1 from "../public/devops_consult_serv.webp";
import devops2 from "../public/cicd_serv.webp";
import devops3 from "../public/iac_serv.webp";
import devops4 from "../public/cloud_migration_serv.webp";
import devops5 from "../public/containerization_serv.webp";
import devops6 from "../public/monitoring_serv.webp";
import mini1 from "../public/mini1.webp";
import mini2 from "../public/mini2.webp";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { motion } from "framer-motion";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/taps";
import { usePathname } from "next/navigation";

function Services() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";
  // const [isSmallScreen,setSmallScreen] = useState(false)
  // window.innerWidth < 992 ? true : false;
  const [isSmallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <section id="services">
      <div className="py-24 sm:px-36 px-4 font-montserrat">
        <h2
          className={`text-slate-950 text-4xl md:text-5xl flex justify-center  font-bold text-center mb-8 ${
            lang == "ar" ? "flex-row-reverse" : ""
          }`}
        >
          <span className="px-2">{t("mintops")}</span>
          <span className="text-blue-800 px-2">{t("services")}</span>
        </h2>
        <p className="text-slate-950 opacity-70 text-xl font-normal text-center px-0 lg:px-64 mb-16">
          {t("services_para")}
        </p>

        <Tabs defaultValue="software" className="w-full">
          <TabsList className="bg-transparent ml-[50%] translate-x-[-50%] flex gap-4 mb-20">
            <TabsTrigger
              value="software"
              className="text-slate-950 border-blue-800 opacity-60 text-xl font-normal bg-transparent data-[state=active]:bg-transparent data-[state=active]:text-blue-800 data-[state=active]:opacity-100 data-[state=active]:border-b-2"
            >
              Software
            </TabsTrigger>
            <TabsTrigger
              value="development"
              className="text-slate-950 border-blue-800 opacity-60 text-xl font-normal bg-transparent data-[state=active]:bg-transparent data-[state=active]:text-blue-800 data-[state=active]:opacity-100 data-[state=active]:border-b-2"
            >
              DevOps
            </TabsTrigger>
          </TabsList>
          <TabsContent value="software">
            <div className="flex justify-between flex-wrap items-center gap-y-8">
             {
              isSmallScreen? 
              <>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <div className="relative">
                  <Image
                    src={security}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-ful"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini2}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                  
                  >
                    {t("service_2_description")}
                  </p>
                </div>

                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_2_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
              <div className="relative">
                <Image
                  src={cloudServices}
                  alt="service"
                  width={2000}
                  height={2000}
                  className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                  style={{
                    objectFit: "contain",
                  }}
                />
                <Image
                  src={mini1}
                  alt="service"
                  className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
                <p
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                >
                  {t("service_3_description")}
                </p>
              </div>
              <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                {t("service_3_title")}
              </h3>
            </div>
            <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
              <div className="relative">
                <Image
                  src={uiux}
                  alt="service"
                  width={2000}
                  height={2000}
                  className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                  style={{
                    objectFit: "contain",
                  }}
                />
                <Image
                  src={mini2}
                  alt="service"
                  className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
                <p
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                >
                  {t("service_4_description")}
                </p>
              </div>
              <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                {t("service_4_title")}
              </h3>
            </div>
            <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
              <div className="relative">
                <Image
                  src={webDev}
                  alt="service"
                  width={2000}
                  height={2000}
                  className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                  style={{
                    objectFit: "contain",
                  }}
                />
                <Image
                  src={mini1}
                  alt="service"
                  className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
                <p
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                  
                >
                  {t("service_5_description")}
                </p>
              </div>
              <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                {t("service_5_title")}
              </h3>
            </div>
            <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
              <div className="relative">
                <Image
                  src={mobileDev}
                  alt="service"
                  width={2000}
                  height={2000}
                  className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                  style={{
                    objectFit: "contain",
                  }}
                />
                <Image
                  src={mini2}
                  alt="service"
                  className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
                <p
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                  
                >
                  {t("service_6_description")}
                </p>
              </div>
              <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                {t("service_6_title")}
              </h3>
            </div>
            <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
              <div className="relative">
                <Image
                  src={customSW}
                  alt="service"
                  width={2000}
                  height={2000}
                  className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                  style={{
                    objectFit: "contain",
                  }}
                />
                <Image
                  src={mini2}
                  alt="service"
                  className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
                <p
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                 
                >
                  {t("service_7_description")}
                </p>
              </div>
              <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                {t("service_7_title")}
              </h3>
            </div>
            </>
              
              :
              <>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                  <motion.div className="relative">
                    <Image
                      src={security}
                      alt="service"
                      width={2000}
                      height={2000}
                      className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-ful"
                      style={{
                        objectFit: "contain",
                      }}
                    />
                    <Image
                      src={mini2}
                      alt="service"
                      className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                    <motion.p
                      className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                      initial={{ opacity:  0 }}
                      whileHover={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      {t("service_2_description")}
                    </motion.p>
                  </motion.div>

                  <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                    {t("service_2_title")}
                  </h3>
                </div>
                <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={cloudServices}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_3_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_3_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={uiux}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini2}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_4_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_4_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={webDev}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_5_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_5_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={mobileDev}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini2}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_6_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_6_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={customSW}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini2}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_7_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_7_title")}
                </h3>
              </div>
              </>
                
             }
              
              
            </div>
          </TabsContent>
          <TabsContent value="development">
            <div className="flex justify-between flex-wrap items-center gap-y-8">
             {
              isSmallScreen ?
              <>
               <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <div className="relative">
                  <Image
                    src={devops1}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                  >
                    {t("service_devops_1_description")}
                  </p>
                </div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_1_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <div className="relative">
                  <Image
                    src={devops2}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-ful"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"

                  >
                    {t("service_devops_2_description")}
                  </p>
                </div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_2_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <div className="relative">
                  <Image
                    src={devops3}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"

                  >
                    {t("service_devops_3_description")}
                  </p>
                </div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_3_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <div className="relative">
                  <Image
                    src={devops4}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{ 
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini2}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"

                  >
                    {t("service_devops_4_description")}
                  </p>
                </div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_4_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <div className="relative">
                  <Image
                    src={devops5}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{  
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"

                  >
                    {t("service_devops_5_description")}
                  </p>
                </div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_5_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <div className="relative">
                  <Image
                    src={devops6}
                    alt="service"
                    width={2000}
                    height={2000}
                    style={{
                      objectFit: "contain",
                    }}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                  />
                  <Image
                    src={mini2}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                  >
                    {t("service_devops_6_description")}
                  </p>
                </div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_6_title")}
                </h3>
              </div>
              
              </>:

              <>
              
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={devops1}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_devops_1_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_1_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={devops2}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-ful"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_devops_2_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_2_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={devops3}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_devops_3_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_3_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={devops4}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{ 
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini2}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_devops_4_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_4_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={devops5}
                    alt="service"
                    width={2000}
                    height={2000}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                    style={{  
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    src={mini1}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_devops_5_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_5_title")}
                </h3>
              </div>
              <div className="w-full md:w-[49%] lg:w-[32.6%] relative bg-white">
                <motion.div className="relative">
                  <Image
                    src={devops6}
                    alt="service"
                    width={2000}
                    height={2000}
                    style={{
                      objectFit: "contain",
                    }}
                    className="w-full mb-4 lg:h-[250px] md:h-[180px] h-[210px] max-w-full"
                  />
                  <Image
                    src={mini2}
                    alt="service"
                    className="absolute bg-blue-800 bottom-0 right-0 max-w-full h-auto"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <motion.p
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center p-4"
                    initial={ { opacity:  0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t("service_devops_6_description")}
                  </motion.p>
                </motion.div>
                <h3 className="text-slate-950 md:text-xl md:h-20 py-4 md:py-2 flex items-center justify-center font-semibold text-center">
                  {t("service_devops_6_title")}
                </h3>
              </div>
              </>
             }
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </section>
  );
}

export default Services;
