"use client";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import { useTranslations } from "next-intl";
import { usePathname, useRouter } from "next/navigation";
import Nav from "./nav";
import { Button } from "./ui/button";
import img1 from "../public/hero-img.webp";
import img2 from "../public/hero-img2.webp";
import img3 from "../public/hero-img3.webp";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

const data = [
  {
    type: "img1",
    title: "we_provide_hero",
    desc: "we_provide_hero_p",
    img: img1,
  },
  {
    type: "img2",
    title: "our_mission",
    desc: "our_mission_content",
    img: img2,
  },
  {
    type: "img3",
    title: "our_vision",
    desc: "our_vision_content",
    img: img3,
  },
  
];

function NewHero() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";
  const router = useRouter();
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideRefs = useRef<(HTMLDivElement | null)[]>([]);


  useEffect(() => {
    const options = {
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute("data-index"));

          setCurrentIndex(index);
        }
      });
    }, options);

    const currentSlideRefs = slideRefs.current;

    currentSlideRefs.forEach((slide) => {
      if (slide) observer.observe(slide);
    });

    return () => {
      currentSlideRefs.forEach((slide) => {
        if (slide) observer.unobserve(slide);
      });
    };
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Nav />
      <div className="relative min-h-screen flex flex-col items-center justify-between overflow-hidden  bg-no-repeat bg-home bg-cover bg-center lg:px-24 px-10  pb-4 pt-16">
        <div className="flex gap-4 items-center justify-between">
          <div className="md:w-1/2 md:text-start text-center md:py-0 pt-14 flex flex-col gap-8">
            <h1 className="leading-[70px] lg:leading-[120px] md:leading-[80px] font-montserrat lg:text-7xl md:text-6xl text-5xl  md:max-w-xl ">
              {t( "we_provide_hero")}
            </h1>
            <p>
              {t("we_provide_hero_p")}
            </p>
            <Button
              className="capitalize mx-auto md:mx-0 hover:from-blue-800 hover:to-blue-500 rounded-full bg-gradient-to-b from-blue-800 to-blue-500 flex justify-center items-center w-[170px] h-10 shado  border border-white"
              onClick={() => router.push(`${lang}/contact-us`)}
            >
              {t("free_consulting")}
            </Button>
          </div>
            <Image
              src={data[currentIndex].img}
              width={2000}
              height={2000}
              alt="hero"
              className="max-w-[450px] w-1/2 md:block hidden"
            />
      </div>
        <Carousel
            dir="ltr"
            className="w-full md:flex hidden flex-col justify-center items-center gap-2"
          >
            <CarouselContent className="w-full">
              {data.map((el, index) => (
                <CarouselItem key={index}>
                  <div
                    ref={(el) => {
                      slideRefs.current[index] = el;
                    }}
                    data-index={index}
                    className="py-0 px-0 bg-transparent text-transparent"
                  >
                    {el.type}
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <div
             className="absolute bottom-0">
              <CarouselNext className="text-blue-800 bg-transparent border-blue-800" />
              <CarouselPrevious className="text-blue-800 bg-transparent border-blue-800" />
            </div>
          </Carousel>
        <div className="flex flex-col gap-2 items-center text-blue-800 mt-auto pt-12  hover:cursor-pointer"
          onClick={()=>scrollToSection('welcome')}>
          <p>{t("scroll_down")}</p>
          <div className="flex flex-col justify-center items-center">
            <Image
              src={"/teenyicons_mouse-outline.svg"}
              width={2000}
              height={2000}
              alt="hero"
              className="w-8"
            />
            <Image
              src={"/line 1.svg"}
              width={2000}
              height={2000}
              alt="hero"
              className="w-[1px] h-10 object-cover"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default NewHero;
