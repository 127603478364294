import React from "react";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import { motion } from "framer-motion";

// Importing images
import s1 from "../public/s1.webp";
import s2 from "../public/s2.webp";
import s3 from "../public/s3.webp";
import s4 from "../public/s4.webp";
import s5 from "../public/s5.webp";
import s6 from "../public/s6.webp";
import s7 from "../public/s7.webp";
import s8 from "../public/s8.webp";
import s9 from "../public/s9.webp";
import s10 from "../public/s10.webp";
import s11 from "../public/s11.webp";
import s12 from "../public/s12.webp";
import s13 from "../public/s13.webp";
import s14 from "../public/s14.webp";
import s15 from "../public/s15.webp";
import s16 from "../public/s16.webp";

const solutionsData = [
  { img: s1, titleKey: "financial_services", descKey: "sol_1" },
  { img: s2, title: "Telecom", descKey: "sol_2" },
  { img: s3, titleKey: "healthcare_and_life_science", descKey: "sol_3" },
  { img: s4, titleKey: "games", descKey: "sol_4" },
  { img: s5, titleKey: "analytics_and_data_lakes", descKey: "sol_5" },
  { img: s6, titleKey: "ai", descKey: "sol_6" },
  { img: s7, titleKey: "serverless_computing", descKey: "sol_7" },
  { img: s8, titleKey: "all_industry_solutions", descKey: "sol_8" },
];

const productsData = [
  { img: s9, titleKey: "amazon_bedrock", descKey: "sol_9" },
  { img: s10, title: "Amazon eC2", descKey: "sol_10" },
  { img: s11, titleKey: "amazon_simple_storage", descKey: "sol_11" },
  { img: s12, titleKey: "amazon_q_developer", descKey: "sol_12" },
  { img: s13, titleKey: "amazon_cloudFront", descKey: "sol_13" },
  { img: s14, titleKey: "amazon_codeCatalyst", descKey: "sol_14" },
  { img: s15, titleKey: "amazon_lightsail", descKey: "sol_15" },
  { img: s16, titleKey: "amazon_dynamoDB", descKey: "sol_16" },
  { img: s8, titleKey: "show_more", centered: true },
];

function Solutions() {
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";
  const t = useTranslations("Index");

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.4 },
    visible: { opacity: 1, scale: 1 },
  };

  const renderCard = (item: any, index: number) => (
    <motion.div
      key={index}
      initial="hidden"
      variants={cardVariants}
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1, delay: index * 0.02 }}
      className={`bg-white rounded-lg p-7 border border-blue-800 lg:w-[30%] sm:w-[45%] w-full ${
        item.centered ? "text-center" : ""
      }`}
    >
      <div
        className={`flex justify-center w-full mb-${
          item.centered ? "8 mt-8" : "6"
        }`}
      >
        <Image
          src={item.img}
          alt="solution"
          className="w-1/2"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </div>
      <h3 className="mb-6 text-slate-950 text-xl font-bold">
        {item.titleKey ? t(item.titleKey) : item.title}
      </h3>
      {!item.centered && (
        <p className="text-slate-950 text-sm font-medium">{t(item.descKey)}</p>
      )}
    </motion.div>
  );

  return (
    <section id="solutions">
      <div className="bg-white py-24 lg:px-20 md:px-10 px-4 font-montserrat">
        <h2 className="text-[40px] font-bold text-center mb-20">
          {t("explore_solutions")}
        </h2>
        <div className="flex justify-between flex-wrap gap-y-14 mb-48">
          {solutionsData.map((item, index) => renderCard(item, index))}
        </div>
        <h2 className="text-white text-[40px] font-bold text-center mb-20">
          {t("explore_our_products")}
        </h2>
        <div className="flex justify-between flex-wrap gap-y-14 mb-24">
          {productsData.map((item, index) => renderCard(item, index))}
        </div>
      </div>
    </section>
  );
}

export default Solutions;
