import React from "react";
import welcome1 from "../public/welcome 1.webp";
import welcome2 from "../public/welcome 2.webp";
import yellow1 from "../public/yellow1.webp";
import yellow2 from "../public/yellow2.webp";
import Image from "next/image";
import { Button } from "./ui/button";
import { useTranslations } from "next-intl";
import { usePathname, useRouter } from "next/navigation";
import { motion, useMotionValue, useTransform } from "framer-motion";

function WhoWeAre() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";
  const router = useRouter();

  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = (event.clientX - rect.left - rect.width / 2) / 2;
    const y = (event.clientY - rect.top - rect.height / 2) / 2;
    mouseX.set(x);
    mouseY.set(y);
  };

  const handleMouseLeave = () => {
    mouseX.set(0);
    mouseY.set(0);
  };

  const xPos = useTransform(mouseX, [-200, 200], [-40, 40]);
  const yPos = useTransform(mouseY, [-200, 200], [-40, 40]);
  return (
    <div className="py-24 md:px-36 px-4 font-montserrat">
      <div className="flex justify-between flex-wrap">
        <div className="lg:w-1/2 w-full relative">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 3, delay: 9 }}
            className={`absolute${
              lang === "ar"
                ? "right-[139px] sm:right-[250px] lg:right-[350px]"
                : "left-[139px] sm:left-[250px] lg:left-[350px]"
            } xl:-bottom-[38px] lg:-bottom-[8px] -bottom-[430px] w-[50%]`}
          >
            <Image
              alt="who"
              src={yellow1}
              className="w-full"
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </motion.div>
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 3, delay: 9 }}
            className={`absolute w-full sm:w-auto ${
              lang === "ar"
                ? "right-0 sm:right-[30px] lg:right-[230px]"
                : "left-0 sm:left-[30px] lg:left-[230px]"
            }  -bottom-[380px] lg:-bottom-[132px] w-[10%]`}
          >
            <Image
              alt="who"
              src={yellow2}
              className="w-full"
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 3, delay: 0 }}
            style={{ objectFit: "cover" }}
            className={`absolute w-[85%] ${
              lang === "ar" ? "left-0" : "right-0"
            } top-0 h-[250px]`}
          >
            <Image
              src={welcome1}
              alt="who"
              fill
              sizes="100vw"
              style={{
                objectFit: "cover",
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 3, delay: 3 }}
            style={{ objectFit: "cover" }}
            className={`absolute w-[70%] ${
              lang === "ar" ? "right-0" : "left-0"
            } top-[180px] h-[250px]`}
          >
            <Image
              src={welcome2}
              alt="who"
              fill
              sizes="100vw"
              style={{
                objectFit: "cover",
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 3, delay: 6 }}
            className={`absolute  lg:w-[45%] sm:w-[55%] w-[80%] bg-blue-800 rounded-lg border-2 border-white p-3 ${
              lang == "ar" ? "left-0" : "right-0"
            } sm:top-[270px] top-[290px]`}
          >
            <div className="border border-dashed border-white p-4 flex justify-between items-center">
              <div
                className={`${
                  lang === "ar" ? "pl-2 border-l" : "pr-2 border-r"
                }  border-white opacity-70`}
              >
                <h3 className="text-white text-[30px] font-bold">23+</h3>
                <h4 className="text-white text-xl font-medium">Years</h4>
              </div>
              <h4
                className={`text-white text-lg font-semibold ${
                  lang === "ar" ? "mr-3" : "ml-3"
                }`}
              >
                {t("In_Developing_And_Operations")}
              </h4>
            </div>
          </motion.div>
        </div>
        <div
          className={`lg:w-1/2 w-full ${
            lang === "ar" ? "pr-[0] sm:pr-16" : "pl-[0] sm:pl-16"
          }  lg:mt-0 mt-[500px]`}
        >
          <h3 className="text-blue-800 text-2xl text-center lg:text-start font-medium mb-4">
            {t("who_we_are")}
          </h3>
          <h2 className="text-slate-950 text-[35px] text-center lg:text-start font-bold mb-6">
            {t("we_provide_best")}
          </h2>
          <p className="text-slate-950 opacity-70 text-lg text-center lg:text-start font-normal mb-10">
            {t("who_we_are_para")}
          </p>
          <div className="flex justify-between">
            <motion.div
              className="sm:w-full w-43 mx-auto text-center flex justify-center"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              style={{ x: xPos, y: yPos }}
            >
              <Button
                onClick={() => router.push(`${lang}/contact-us`)}
                className="capitalize hover:from-blue-800 hover:to-blue-500 rounded-full bg-gradient-to-b from-blue-800 to-blue-500 flex justify-center items-center w-[150px] h-10 shado  border border-white"
              >
                {t("free_consulting")}
              </Button>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
